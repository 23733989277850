<template>
  <a-card :bordered="false">
    <div>
      <a-form :form="form" v-bind="formLayout">
        <a-row>
          <a-col :span="12">
            <a-form-item label="企业名称" :labelCol="formLayout.labelCol" :wrapperCol="formLayout.wrapperCol">
              <a-input v-decorator="['name', {rules: [{required: true, message: '请输入'}, {max:50, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="英文名" :labelCol="formLayout.labelCol" :wrapperCol="formLayout.wrapperCol">
              <a-input v-decorator="['en_name', {rules: [{required: false, message: '请输入'}]}]" />
            </a-form-item>
            <a-form-item label="注册资本" :labelCol="formLayout.labelCol" :wrapperCol="formLayout.wrapperCol">
              <a-input v-decorator="['registered_capital', {rules: [{required: true, message: '请输入'}, {max:50, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="法人" :labelCol="formLayout.labelCol" :wrapperCol="formLayout.wrapperCol">
              <a-input v-decorator="['legal_person', {rules: [{required: true, message: '请输入'}, {max:50, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="法人手机" :labelCol="formLayout.labelCol" :wrapperCol="formLayout.wrapperCol">
              <a-input v-decorator="['legal_person_cellphone', {rules: [{required: false, message: '请输入'}, {max:50, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="统一社会信用代码" :labelCol="formLayout.labelCol" :wrapperCol="formLayout.wrapperCol">
              <a-input v-decorator="['credit_code', {rules: [{required: true, message: '请输入'}, {max:50, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="企业注册地址" :labelCol="formLayout.labelCol" :wrapperCol="formLayout.wrapperCol">
              <a-input v-decorator="['domicile', {rules: [{required: true, message: '请输入'}, {max:50, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="是否是规模以上企业" :labelCol="formLayout.labelCol" :wrapperCol="formLayout.wrapperCol">
              <a-switch v-decorator="['is_above_size', {initialValue: false, valuePropName: 'checked'}]" />
            </a-form-item>
            <a-form-item label="在园区内的企业状态" :labelCol="formLayout.labelCol" :wrapperCol="formLayout.wrapperCol">
              <a-select
                v-decorator="[
                  'status',
                  {
                    rules: [
                      { required: true, message: '请选择' },
                    ],
                  },
                ]"
                placeholder="选择"
              >
                <a-select-option :value="1">
                  待入园
                </a-select-option>
                <a-select-option :value="2">
                  已入园
                </a-select-option>
                <a-select-option :value="3">
                  已注销
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="所在园区" :labelCol="formLayout.labelCol" :wrapperCol="formLayout.wrapperCol">
              <a-select
                v-decorator="[
                  'location',
                  {
                    rules: [
                      { required: true, message: '请选择'},
                    ],
                  },
                ]"
                placeholder="选择"
              >
                <a-select-option v-for="item in this.$Dictionaries.location" :value="Object.keys(item)[0]" :key="Object.values(item)[0]"> {{ Object.values(item)[0] }} </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="进出口企业代码" :labelCol="formLayout.labelCol" :wrapperCol="formLayout.wrapperCol">
              <a-input v-decorator="['import_export_code', {rules: [{required: false, message: '请输入'}, {max:50, message: '输入超出长度限制'}]}]" />
            </a-form-item>

            <a-form-item label="备注" :labelCol="formLayout.labelCol" :wrapperCol="formLayout.wrapperCol">
              <a-textarea v-decorator="['remark', {rules: [{required: false, message: '请输入'}, {max:100, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item
          v-bind="buttonCol"
        >
          <a-row>
            <a-col span="6">
              <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
            </a-col>
            <a-col span="10">
              <a-button @click="handleGoBack">返回</a-button>
            </a-col>
            <a-col span="8"></a-col>
          </a-row>
        </a-form-item>
      </a-form>
    </div>
  </a-card>
</template>

<script>
// import moment from 'moment'
import pick from 'lodash.pick'
import { corporation_update, corporation_list } from '@/api/corporation'

export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 3 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 6, offset: 10 }
        }
      },
      form: this.$form.createForm(this),
      id: 0
    }
  },
  // beforeCreate () {
  //   this.form = this.$form.createForm(this)
  // },
  mounted () {
    this.$nextTick(() => {
      // this.loadEditInfo(this.record)
      corporation_list({ id: this.$route.params.id })
        .then(res => {
          console.log(res.data, '我是数据列表@')
          const list = res.data.entries
          if (list && list.length > 0) {
            this.loadEditInfo(list[0])
          }
        })
    })
  },
  methods: {
    handleGoBack () {
      // this.$emit('onGoBack')
      this.$router.go(-1)
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        console.log('我要提交接口', values)
        if (!err) {
          // eslint-disable-next-line no-console
          console.log('Received values of form: ', values)
        }
        if (values.remark === null || values.remark === undefined) {
          values.remark = ''
        }
        corporation_update(values, this.id)
          .then((res) => {
            this.$notification.success({
              message: '修改成功',
              description: `修改成功`
            })
            this.handleGoBack()
            console.log(res, '修改成功')
    })
      })
    },
    handleGetInfo () {},
    loadEditInfo (data) {
      const { form } = this
      console.log(`将加载 ${this} 信息到表单`)
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        const formData = pick(data, ['id', 'name', 'en_name', 'registered_capital', 'domicile', 'credit_code',
          'import_export_code', 'legal_person', 'legal_person_cellphone', 'status', 'location', 'is_above_size', 'remark', 'tags'])
        console.log('formData', formData)
        formData.location = String(formData.location)
        this.id = formData.id
        form.setFieldsValue(formData)
      })
    }
  }
}
</script>
